.main {
    width: 100%;
    height: 100%;
    padding: 3rem 1.5rem;
    box-sizing: border-box;
}

.main * {
    box-sizing: border-box;
}

.container {
    display: grid;
    width: 100%;
    gap: 1.5rem;
    max-width: 1800px;
    margin: 0 auto;
}

.right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
}

.videoContainer {
    width: fit-content;
    height: fit-content;
    padding: .625rem;
}

.videoWrapper {
    width: fit-content;
    height: fit-content;
    box-shadow: 0px 0px 40px -4px rgba(0, 0, 0, 0.68);   
    border-radius: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bannerVideo {
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 1rem;
    outline: .875rem solid #ffffff54; 
}

.left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: rgb(28, 16, 92);
}

.content {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
}

.authorVideoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.authorVideo{
    aspect-ratio: 1/1;
    width: 100%;
    max-width: 350px;
}

@media (min-width: 768px) {
    .container {
        grid-template-columns: repeat(2, 1fr);
    }
}