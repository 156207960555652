.primaryBtn {
    border: 1px solid #6710f7;;
    border-radius: 1.75rem;
    padding: 1rem 1.25rem;
    color: white;
    font-family: Poppins;
    font-weight: var(--font-weight-semiBold);
    font-size: 1rem;
    min-width: 5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: max-content;
    background: #6710f7;
    background: linear-gradient(to right, #650ff7, #9426eb);
}

.primaryBtn:focus {
    background: #6710f7;
}

.primaryBtn:hover:not([disabled]) {
    background-position: left bottom;
    background: #6710f7;
}

.primaryBtn:active:not([disabled]) {
    outline: 0;
    background: #6710f7;
    box-shadow: none;
}

.primaryBtn:focus {
    outline: 0;
    box-shadow: none;
}

.primaryBtn:disabled {
    opacity: 0.6;
    pointer-events: none;
}