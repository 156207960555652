.button {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 50;
    padding: 0.75rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
}

.show {
    opacity: 1;
    transform: translateY(0);
}

.hide {
    opacity: 0;
    transform: translateY(2.5rem);
    pointer-events: none;
    
}