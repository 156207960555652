.main {
    margin-top: 3.2rem;
    box-sizing: border-box;
}

.main * {
    box-sizing: border-box;
}

.heroSection {
    position: relative;
    width: calc(100dvw-3.2rem);
    height: calc(100dvh-3.2rem);
    color: white;
}

.heroSection img {
    width: 100%;
    object-fit: contain;
}

.heroContent {
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    padding-right: 50%;
    padding-left: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.heroContentWrapper {
    width: 100%;
    height: max-content;
    margin: auto;
}

.heroTitle {
    font-family: "Big Shoulders Text", serif;
    font-size: max(4vw, 2.5rem);
    font-weight: 900;
}

.createButton {
    width: max-content;
    height: max-content;
    padding: 1rem 2rem;
    font-size: 1.5rem;
    font-weight: 600;
    border-color: #6710f7;
    border-radius: 10rem;
    background: #6710f7;
    background: linear-gradient(to right, #650ff7, #9426eb);
}

.container {
    width: 100%;
    padding: 3rem 1.5rem;
    max-width: 85rem;
    margin-inline: auto;
}

.contentWrapper {
    width: 100%;
    height: 100%;
}

.bottomSection {
    width: 100%;
    height: 100%;
    position: relative;
}

.bottomSection img{
    width: 100%;
    object-fit: contain;
}

.bottomContent {
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 55%;
    padding-block: 2.5rem;
    display: flex;
}

.bottomContentWrapper {
    width: 100%;
    height: max-content;
    margin: auto;
}

.bottomHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
}

.bottomHeader h2 {
    font-family: "Big Shoulders Text", serif;
    font-size: 2.75rem;
    font-weight: 900;
}

.bottomHeader p {
    font-family: "Big Shoulders Text", serif;
    font-size: 2.5rem;
    font-weight: 900;
    text-align: center;
}

.bottomHeader span {
    color: #fff600;
}

.bottomCards {
    width: 100%;
    padding: 1.5rem;
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: #fff;
    color: var(--heading-dark-color);
    text-align: center;
    margin-top: 1rem;
}

.card {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card:first-child {
    border: 1px solid var(--heading-dark-color);
    border-top-width: 0px;
    border-left-width: 0px;
}

.card:nth-child(2) {
    border: 1px solid var(--heading-dark-color);
    border-top-width: 0px;
    border-inline-width: 0px;
}

.card:nth-child(3) {
    border: 1px solid var(--heading-dark-color);
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
}

.card h3 {
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .heroContent {
        padding: 2.5rem 1.5rem;
        align-items: flex-end;
    }

    .heroContentWrapper {
        margin: 0;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .heroTitle {
        text-align: center;
    }

    .bottomContent {
        padding-inline: .5rem;
        padding-block: 1rem;
    }

    .bottomContentWrapper {
        margin-bottom: 0;
    }

    .bottomCards {
        border-radius: 3rem;
        padding: .75rem;
        max-width: 330px;
        max-height: 330px;
        margin-inline: auto;
    }

    .card h3 {
        font-size: 1rem;
    }

    .card p {
        font-size: .875rem;
    }
}