.mainContainer {
    margin-top: 3.2rem;
    background-color: #fff;
}

.aboutBannerMain {
    width: 100%;
    background-color: #fff;
}

.aboutImageContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: -.6rem;
}

.aboutImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.createButtonWrapper {
    position: absolute;
    left: 7vw;
    top: 31.75vw;
}

.aboutCreateButton {
    width: max-content;
    height: max-content;
    padding: max(1vw, 0.5rem) max(1.25vw, 0.75rem);
    font-size: max(2vw, 1.25rem);
    font-weight: 600;
    border-color:#6710f7;
    border-radius: 10rem; 
    background: #6710f7;
    background: linear-gradient(to right, #650ff7, #9426eb);
}

.aboutCreateButton:hover {
    background: var(--selection-dark) !important;
    color: #fff !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.createBtn {
    border-color:#6710f7;
    border-radius: 10rem; 
    background: #6710f7;
    background: linear-gradient(to right, #650ff7, #9426eb);
}

.createBtn:hover {
    background: var(--selection-dark) !important;
    color: #fff !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.buttonBar {
    color: #fff;
    background: linear-gradient(to right, #650ff7, #9426eb);
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1.5rem;
    flex-wrap: wrap;
}

.buttonBar button {
    color: #fff;
    flex: 1;
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    font-family: "Anton", sans-serif;
    font-weight: 900;
    word-wrap: none;
    word-break: keep-all;
    padding: 0.5rem 0.75rem;
    min-width: max-content;
}

.buttonBar button:hover, button:focus, button:active, button:focus-visible, button:focus-within {
    color: var(--selection-dark);
    background: #fff;

}

.aboutContentSection {
    margin: 0 auto;
    padding: 3.5rem 1.5rem;
    border-radius: 8px;
    max-width: 80rem;
}

.aboutContentSection > h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
    text-align: center;
    color: #333;
}

.ourTeamHeading {
    margin-top: 3rem;
}

.aboutContentSection > p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #555;
}

.aboutContentSection > p:last-child {
    margin-bottom: 2.5rem;
}

.ourTeamContainer {
    --columns: 1;
    margin-top: 2rem;
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(var(--columns), 1fr);
}

@media (min-width: 768px) {
    .ourTeamContainer {
        --columns: 2;
    }
}

@media (min-width: 1024px) {
    .ourTeamContainer {
        --columns: 3;
    }
}

@media (max-width: 768px) {
    .aboutBannerMain {
        width: 100%;
    }

    .createButtonWrapper {
        top: unset;
        left: 50%;
        bottom: 6vh;
        transform: translateX(-50%);
    }
}