.section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 4rem 1rem;
    color: rgb(28, 16, 92);
}

.reverse {
    flex-direction: column-reverse;
}

.content {
    flex: 1;
    max-width: 36rem;
}

.title {
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 2rem;
    font-family: "Big Shoulders Text", serif;
}

.bulletPoints {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.bulletPoint {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    padding-left: 2rem;
    position: relative;
    font-size: 1.2rem;
}

.bulletPoint::before {
    content: "";
    background: -webkit-gradient(linear, left top, right top, from(#5d1de1), to(#8f41ff));
    background: linear-gradient(90deg, #5d1de1 0%, #8f41ff 100%);
    width: .875rem;
    height: .875rem;
    position: absolute;
    top: 6px;
    left: 0px;
    border-radius: 50%;
    overflow: hidden;
}

.imageWrapper {
    flex: 1;
    width: 100%;
    max-width: 42rem;
    display: flex;
    align-items: center;
    justify-content: center;

}

.imageContainer {
    border-radius: 3rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    width: 100%;
    max-width: 300px;
    height: max-content;
    padding: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    width: 100%;
    max-width: 200px;
    height: auto;
    border-radius: 0.5rem;
    margin: 1rem;
}

@media (min-width: 768px) {
    .section {
        flex-direction: row;
        padding: 4rem 2rem;
    }

    .reverse {
        flex-direction: row-reverse;
    }
}