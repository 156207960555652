.mainContainer {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin-top: 3.2rem;
}

.heroImageContainer {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.heroImageContainer img {
    width: 100dvw;
    height: 100dvh;
    object-fit: cover;
}

.heroImageContainer div:not(:last-child) {
    width: 100% !important;
    height: auto !important;
}

.heroImageContainer video {
    width: 100%;
    object-fit: cover;
}

.createButton {
    --btn-color: var(--selection-dark);
    --btn-bg: #fff;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 26vw;
    top: 28vw;
}

.createButton button {
    font-size: max(2vw, 1.25rem);
    font-weight: 600;
    padding: 1rem
}

.createMobileButton {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10vw;
}

.createMobileButton button {
    font-size: max(3vw, 1.25rem);
    font-weight: 600;
    padding: 1rem
}

.heading {
    font-family: "New Walt Disney";
    color: white;
    font-size: 4.5rem;
    font-weight: normal;
    margin-top: 1rem;
}

.editorChoiceHeading {
    font-size: 2.375em;
    font-weight: var(--font-weight-bold);
    color: white;
    text-align: center;
    margin-bottom: 3rem;
}

.editorChoiceMain {
    width: 100%;
    height: max-content;
    overflow: hidden;
    position: relative;
    margin-top: -0.4rem;
    background: linear-gradient(67deg,
            rgb(52, 28, 176),
            rgb(42, 22, 141),
            rgb(111, 47, 135));
    padding: min(3rem, 5vw);
    box-sizing: border-box;
}

.editorsChoiceWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, minmax(8rem, 1fr));
    gap: 1rem;
    overflow: hidden;
}

.editorsChoiceWrapper>a {
    overflow: hidden;
    display: flex;
    height: fit-content;
}

.editorsChoiceWrapper>a>img {
    width: 100%;
    object-fit: contain;
    border-radius: 1.25rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.35s ease-in-out;
}

.editorsChoiceWrapper img:hover {
    transform: scale(1.1);
}

.imageContainer {
    width: 100%;
    height: max-content;
    overflow: hidden;
    position: relative;
}

.editorsChoiceWrapper .editorsChoiceImageContainer {
    flex: 0.25;
    width: 100%;
    height: 100%;
}

.sectionHeading {
    width: 100%;
    text-align: center;
    font-size: 2.375rem;
    font-weight: var(--font-weight-bold);
    color: white;
}

.b2bInfoContainer {
    width: 100%;
    padding: 5rem 0;
    background: #ffffff;
}

.noBooks {
    grid-column: span 5;
    text-align: center;
    padding-bottom: 1.5rem;
    color: #fff;
}

.b2bContentWrapper {
    width: 100%;
    max-width: 85rem;
    padding: 2rem;
    margin: auto;
    display: grid;
    gap: 2rem 2.5rem;
    box-sizing: border-box;
}

.b2bButton {
    color: var(--selection-dark);
    background: rgba(111, 47, 135, 0.1);
    border: 1px solid var(--selection-dark);
}

.b2bButton:hover {
    background: var(--app-dark) !important;
    color: #fff;
}

.b2bBannerImageWrapper {
    border-radius: 1rem;
    overflow: hidden;
    padding: 0.5rem;
    border: 2px solid var(--selection-dark);
    aspect-ratio: 3/4;
}

.b2bMarquee {
    height: 100%;
    width: 100%;
}

.b2bMarquee div {
    height: 100%;
}

.b2bMarqueeChild {
    aspect-ratio: 3/4;
    padding: .5rem;
    box-sizing: border-box;
}

.b2bBannerImageWrapper img {
    border-radius: 1rem;
    aspect-ratio: 3/4;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.b2bHeader {
    margin-bottom: 2.5rem;
}

.b2bHeader h1 {
    font-family: Poppins, sans-serif;
    font-size: 2.20rem;
    text-align: center;
    margin: 0;
    margin-bottom: 1.2rem;
}

.b2bHeader h2 {
    color: var(--selection-dark);
    font-size: 1.875rem;
    font-family: Poppins, sans-serif;
    margin-bottom: 1rem;
    font-weight: 500;
}

.b2bHeader p {
    font-size: 1.25rem;
    font-family: Poppins, sans-serif;
    font-weight: 400;
}

.b2bWork {
    margin-bottom: 1.5rem;
}

.b2bWork>h4 {
    color: var(--selection-dark);
    font-size: 1.875rem;
    font-family: Poppins, sans-serif;
    margin: 0;
    font-weight: 500;
}

.b2bWorkList li {
    font-size: 1.25rem;
    font-family: Poppins, sans-serif;
    font-weight: 400;
}

.seoBannerMain {
    width: 100%;
    background-color: #fff;
    margin: -.5rem 0;
}

.seoHeader {
    position: relative;
}

.seoCreateButtonWrapper {
    position: absolute;
    bottom: 1.5vw;
    right: 10vw;
}

.seoCreateButton {
    height: max-content;
    background: #fff;
    color: var(--text-light-color);
    padding: .25rem 1rem;
    font-size: max(2vw, .875rem);
}

.seoCreateButton:hover {
    background: var(--selection-dark) !important;
    color: #fff !important;
}

.seoImageContainer {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.seoImageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: content;
}

.seoContent {
    width: 100%;
    max-width: 85rem;
    padding: 3rem 2rem;
    margin: auto;
    display: grid;
    gap: 3.5rem;
    box-sizing: border-box;
}

.seoContent div {
    font-size: 1.25rem;
    font-family: Poppins, sans-serif;

}

.testimonialsMain {
    width: 100%;
    background-color: #fff;
    padding: 6rem 0;
    position: relative;
    overflow: hidden;
}

.testimonialsHeading {
    max-width: 44rem;
    text-align: center;
    margin: auto;
    padding: 1rem;
}

.testimonialsHeading h1 {
    font-size: min(23vw, 8rem);
    font-family: "Big Shoulders Text", serif;
    color: rgb(28, 16, 92);
}

.testimonialsContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
}

.testimonialsCarousalWrapper {
    height: 100%;
}

.testimonialsCarousal {
    display: flex;
    flex-direction: column;
    animation: scroll 35s linear infinite;
}

.testimonialsCarousal * {
    box-sizing: border-box;
}

.testimonialCardWrapper {
    border-radius: 1rem;
    color: #fff;
    padding: 1rem;
    flex-grow: 1;
    width: 100%;
    height: fit-content;
    display: flex;
    min-height: 10rem;
}

.testimonialCard {
    background: rgb(113, 72, 181);
    background: linear-gradient(151deg, rgba(113, 72, 181, 1) 0%, rgba(129, 96, 219, 1) 48%, rgba(113, 93, 211, 1) 100%);
    width: max-content;
    height: max-content;
    max-width: 24rem;
    border-radius: 1rem;
    padding: 1.25rem ;
}

.testimonialCardHeader {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 300;
}

.testimonialsBtn {
    border: 1px solid rgb(28, 16, 92);
    color: rgb(28, 16, 92);
    position: relative;
    z-index: 1;
}

.testimonialCardContent {
    display: flex;
    gap: 1rem;
}

.testimonialCardAuthor {
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    border: 5px solid #ccc;
    aspect-ratio: 1/1;
}

.testimonialCardAuthor img {
    width: 100%;
    border-radius: 100%;
    object-fit: contain;
}

.testimonialCardAuthorDetails > h4 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: .5rem;
}

.testimonialCardAuthorDetails > hr {
    background: #fff;
}

.testimonialCardAuthorDetails > p {
    font-size: 1rem;
    font-weight: 600;
}

@keyframes scroll {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-50%);
    }
  }

@media (min-width: 1024px) {
    .seoContent {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .seoImageContainer {
        aspect-ratio: unset;
    }

    .b2bContentWrapper {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .b2bBannerImageWrapper {
        grid-column: span 1 / span 1;
    }

    .b2bContent {
        grid-column: span 2 / span 2;
    }
}

@keyframes borderAnimation {
    from {
        background-position: 0 0, -20px 0, 100% -20px, 0 100%;
    }

    to {
        background-position: 0 -20px, 0 0, 100% 0, -20px 100%;
    }
}

@media (max-width: 819px) {

    .b2bBannerImageWrapper {
        grid-column: span 3 / span 3;
    }

    .b2bContent {
        grid-column: span 3 / span 3;
    }

    .editorsChoiceWrapper {
        grid-template-columns: repeat(3, minmax(8rem, 1fr));
    }

    .editorChoiceMain {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .editorChoiceMain {
        font-size: 12px;
    }

    .sectionHeading {
        font-size: 1.375rem;
    }

    .editorChoiceHeading {
        font-size: 1.375rem;
    }
}