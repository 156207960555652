@import "./assets/fonts/fonts.css";
@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100..900&display=swap');

#root {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

:root {
    --label-margin-bottom: 0.5rem;
    --panel-semi-dark-background: rgb(255, 255, 255);
    --panel-dark-background: rgb(255, 255, 255);
    --panel-light-background: rgb(81, 81, 81);
    --panel-semi-light-background: rgb(25, 33, 42);
    --text-light-color: rgb(16, 16, 16);
    --input-background-color: rgb(242, 244, 248);
    --text-dark-color: rgb(255, 255, 255);
    --border-light-color: rgb(205, 206, 208);
    --btn-primary-color: rgb(70, 158, 246);
    --panel-highlight-color: dimgray;
    --heading-font-size: 1.5rem;
    --heading-font-weight: 400;
    --header-color: rgb(248, 248, 248);
    --app-dark: rgb(106, 48, 137);
    --selection-dark: rgb(108, 18, 171);
    --selection-light: rgb(243, 231, 255);
    --selected-theme-color: rgb(232, 176, 62);
    --heading-dark-color: rgb(28, 16, 92);
    --background-color: rgb(234, 230, 240);
    --app-danger: #ce0c0c;
    /* font sizes */
    --font-weight-thin: 100;
    --font-weight-extra-light: 200;
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semiBold: 600;
    --font-weight-bold: 700;
    --font-weight-extra-bold: 800;
    --font-weight-black: 900;
    --dropDown-menu-hover: rgb(252, 220, 198);
    ;

    --button-color-yellow: #FAA808;
    --button-color-orange: #F26E14;

    --landingPage-form-buttom: #FF007A;
    --landingPage-text: #002690;
}

body {
    margin: 0px;
    font-family: Poppins, sans-serif;
    background-color: var(--panel-dark-background);
}

.app {
    text-align: center;
}

h2,
h3,
p {
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

ul {
    list-style-type: none;
    padding-inline-start: 0;
}

.MuiSlider-root {
    color: darkgray !important;
}

.canvas-scale-slider {
    height: 100%;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem 0.5rem;
}

.canvas-scale-slider .MuiSlider-root {
    color: var(--selection-dark) !important;
}

.MuiDialogActions-root {
    padding: 1.5rem !important;
}

input.MuiInputBase-input {
    padding-left: 8px;
}

span.MuiSlider-valueLabel {
    left: calc(-50% + 2px);
    top: -15px;
}

span.MuiSlider-valueLabel>span {
    font-size: 0.5rem;
    width: 20px;
    height: 20px;
}

.x-item-disabled {
    opacity: 0.4;
    pointer-events: none;
}

.align-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.align-vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.align-horizontal-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.scroll-vertical {
    overflow-y: auto;
    overflow-x: hidden;
}

.scroll-vertical :global(.infinite-scroll-component__outerdiv) {
    height: 100%;
}

.hidden {
    display: none;
}

.text-60 {
    font-size: 60px !important;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: #1C105C;
}

::-webkit-scrollbar-thumb {
    border-radius: 18px;
    background-color: #DB6412;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #c5570d;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:active {
    background-color: #bd5109;
    border-radius: 10px;
}

div {
    scrollbar-width: thin;
}

button:focus {
    outline: none;
}

.react-switch-handle {
    background-color: var(--selection-dark) !important;
}

.react-switch-handle {
    box-shadow: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

.MuiDialogActions-spacing> :not(:first-child) {
    margin-left: 12px !important;
}

.MuiTypography-root.MuiTypography-h6 {
    font-size: 1.25rem;
    font-family: "Poppins";
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    color: #101010;
}

div.MuiTabPanel-root {
    padding: 0;
    padding-top: 1rem;
}

.MuiTab-root .MuiTab-wrapper {
    font-family: "Poppins";
}

.MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: var(--selection-dark);
}

button,
.cursorPointer {
    cursor: pointer;
}

a:has(div) {
    text-decoration: none;
}

@media only screen and (min-width: 480px) and (max-width: 820px) {
    html {
        font-size: 14px;
        padding: 14px;
    }
}

@media (max-width: 480px) {
    html {
        font-size: 12px;
    }
}

.st0.star {
    opacity: 1;
    animation: fade 2s infinite linear;
    fill: rgb(247, 152, 64) !important;
}

@keyframes fade {

    0%,
    100% {
        opacity: 0
    }

    50% {
        opacity: 1
    }
}


#container {
    position: absolute;
    margin: auto;
    width: 52vw;
    height: 80pt;
    filter: url(#threshold) blur(0.6px);
}

#text1,
#text2 {
    position: absolute;
    width: 100%;
    display: inline-block;
    font-family: "Raleway", sans-serif;
    color: yellow;
    font-size: 3vw;
    text-align: center;
    user-select: none;
}

iframe.ql-video {
    width: 50vw;
    height: 25vw;

}

.ql-editor {
    line-height: 32px;
    letter-spacing: 0.5px;
    padding: 0;
}

.fixed {
    position: fixed;
    top: 50%;
    /* Adjust this value according to your layout */
    /* Additional styles for the fixed position */
}

.scrollable {
    position: relative;
    /* or position: relative; based on the layout */
    /* Additional styles for the scrollable position */
}


@media screen and (min-width: 820px) {
    iframe.ql-video {
        width: 35vw;
        height: 17.5vw;
    }
}

.ql-editor a {
    text-decoration: none;
}

.ql-editor a:hover {
    text-decoration: underline;
}


.ql-font-Arial {
    font-family: "Arial", Helvetica, Arial, sans-serif;
}

.ql-font-Arvo {
    font-family: "Arvo", Helvetica, Arial, sans-serif;
}

.ql-font-Cormorant {
    font-family: "Cormorant", Helvetica, Arial, sans-serif;
}

.ql-font-Futura {
    font-family: 'Futura', Helvetica, Arial, sans-serif;
}

.ql-font-Georgia {
    font-family: "Georgia", Helvetica, Arial, sans-serif;
}

.ql-font-Grandstander {
    font-family: "Grandstander", Helvetica, Arial, sans-serif;
}

.ql-font-Hind {
    font-family: "Hind", Helvetica, Arial, sans-serif;
}

.ql-font-Lato {
    font-family: "Lato", Helvetica, Arial, sans-serif;
}

.ql-font-Lobster_Two {
    font-family: "Lobster Two", Helvetica, Arial, sans-serif;
}

.ql-font-Montserrat {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
}

.ql-font-Noto_Serif {
    font-family: "Noto Serif", Helvetica, Arial, sans-serif;
}

.ql-font-Nunito {
    font-family: "Nunito", Helvetica, Arial, sans-serif;
}

.ql-font-Open_Sans {
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.ql-font-Poppins {
    font-family: 'Poppins', Helvetica, Arial, sans-serif;
}

.ql-font-Roboto {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
}

.ql-font-Times_New_Roman {
    font-family: 'Times New Roman', Helvetica, Arial, sans-serif;
}