.main {
    width: 100%;
    height: 100%;
    padding-top: 5rem;
}

.main * {
    box-sizing: border-box;
}

.main h2 {
    padding-inline: 1.5rem;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    text-align: center;
    color:rgb(28, 16, 92);

}

.marqueeWrapper {
    margin: .5rem 0;
}

.themeImageWrapper {
    width: fit-content;
    height: fit-content;
    margin: 0 .5rem;
}

.themeImageWrapper img {
    width: 100%;
    object-fit: contain;
    border-radius: .5rem;
    box-shadow: 0 5px 12px 0 rgba(89,94,103,.07),0 10px 30px 0 rgba(89,94,103,.085);
}

.createBtnWrapper {
    width: max-content;
    margin: 3rem auto;
}

.createBtn {
    border: 1px solid rgb(28, 16, 92);
    color: #fff;
    position: relative;
    z-index: 1;
    background: linear-gradient(to right, #650ff7, #9426eb)
}