.askNameContainer {
  width: 30rem;
}

.askNameContainer div {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.askNameContainer input {
  width: 100%;
  color: var(--text-light-color);
  background-color: transparent;
  border: none;
  border-bottom: 1px solid gray;
}

.disclaimerMessage>p {
  margin-bottom: 1rem;
}

.askNameContainer input:focus-within {
  outline: none;
  border-bottom: 1px solid lightgray;
}

.askNameFooter {
  display: flex;
  justify-content: flex-end;
}

.middleContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  background: #f0f1f5;
}

.footer {
  position: relative;
  padding: 0.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background: var(--panel-dark-background);
}

.actions {
  position: relative;
  padding: 0 1rem 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.bookSaved {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -110%);
}

.footerButton {
  padding: 0.5rem 0.625rem;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  min-width: 3rem;
}

.fullScreenBtn {
  width: 3rem;
}

.saveNotification {
  position: absolute;
  top: 40%;
  left: 1rem;
  transform: translateY(-50%);
}

.pagethumb {
  padding: 0 0.5rem 0.25rem;
  border: solid transparent;
  position: relative;
  color: var(--text-light-color);
}

.pagethumb .deletePageIcon {
  position: absolute;
  right: 0.75rem;
  top: 0.7rem;
  background-color: white;
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
}

.deletePageIcon>img {
  cursor: pointer;
  width: 1rem;
}

.pagethumb .pageView {
  width: 10rem;
  height: 8rem;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.pagethumb.selected {
  border: 2px solid var(--selection-dark);
  scroll-behavior: smooth;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.ribbon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  background: rgb(126, 82, 161);
  background: linear-gradient(90deg, #00c4cc, #7d2ae8);
}

.ribbonControls {
  display: flex;
  padding: 0 0.7rem;
  gap: 0.7rem;
}

/* .ribbonControls > * {
    margin-left: 0.625rem;
} */

.ribbonControls * {
  font-size: 0.9rem;
}

.eula {
  display: flex;
  justify-items: center;
}

.modelDialog :global(.MuiDialog-paperWidthSm) {
  max-width: 90vw;
}

@media (orientation: landscape) {
  div.eulaContainer {
    height: 75vh;
    width: 75vw;
  }
}

@media (orientation: portrait) {
  div.eulaContainer {
    padding: 0;
  }

  .modelDialog :global(.MuiDialog-paperWidthSm) {
    transform: rotate(90deg);
    max-width: 100vh;
    min-width: 75vh;
    width: 76rem;
    height: 90vw;
  }
}

.eulaContainer>div {
  height: 100%;
  width: 100%;
}

.eulaContainer iframe {
  background: white;
  height: 100%;
  width: 100%;
}

.pageNumber {
  display: flex;
  justify-content: center;
}

.pageAdder {
  display: flex;
  justify-content: center;
  border: 2px black dashed;
  height: 7rem;
  align-items: center;
  margin: 0.75rem;
  cursor: pointer;
}

.check {
  margin: 0.5rem 0;
}

.disclaimerPoints {
  margin: 0.5rem 0;
}

.buttonIcon {
  width: 3rem;
}

.addNewButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.colorStrip {
  width: 36.8rem;
}

.leftController {
  /* width: 23.75rem; */
}

.dialogContent {
  height: 100vh;
}

.dialogContent>div {
  width: 100%;
  height: 100%;
}

.canvasAndFooter {
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 90%;
}

.myPages {
  padding: 0.5rem;
  text-align: center;
}

.pagePanel {
  overflow: auto;
}

.uploadImageDeleteIcon {
  position: absolute;
  right: 0.55rem;
  top: 0.5rem;
  background-color: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  z-index: 1;
}

.viewToolbarButton {
  display: none;
}

@media screen and (max-width: 820px) {
  .ribbon {
    flex-wrap: wrap;
    row-gap: 1rem;
  }

  .pagethumb .pageView {
    width: 7rem;
  }

  .colorStrip {
    width: auto;
  }

  .leftController {
    width: auto;
  }

  .footer {
    flex-direction: column;
    row-gap: 1rem;
  }

  .addNewButtonWrapper {
    justify-content: center;
  }

  .footerButton {
    width: fit-content;
  }
}

@media screen and (max-width: 1180px) {
  .footer {
    flex-direction: column;
    row-gap: 1rem;
  }

  .addNewButtonWrapper {
    justify-content: center;
  }

  .leftController {
    width: auto;
  }

  .footerButton {
    width: fit-content;
  }

  .pagethumb .pageView {
    width: 7rem;
  }

  .colorStrip {
    width: auto;
  }
}

.sidebarMenu {
  display: none;
}

.leftControllerContainer {
  display: block;
}

@media screen and (max-width: 500px) {
  .leftControllerContainer {
    display: none;
  }

  .footer {
    flex-direction: row;
  }

  .colorStrip {
    display: none;
  }

  .hideRibbonControls {
    display: none;
  }

  .closeIcon {
    display: none;
  }

  .canvasAndFooter {
    flex-direction: column;
    height: 20%;
  }

  .myPages {
    padding: 0.5rem;
    text-align: start;
  }

  .myPages {
    display: none;
  }

  .pageNumber {
    margin-bottom: 0;
    transform: translateY(-9px);
  }

  .pagePanel {
    display: flex;
    flex-direction: row;
  }

  .pagePanel>div {
    display: flex;
    flex-direction: row;
    width: 18rem;
  }

  .toolBoxButton {
    width: 10rem;
    margin-top: 0.75rem;
  }

  .pageAdder {
    padding: 0rem 2rem;
  }

  .uploadImageDeleteIcon {
    right: 0.125rem;
    top: 0.125rem;
    padding: 2px;
  }

  .pageNumber {
    position: absolute;
    width: fit-content;
    background: inherit;
    padding: 0.1rem 0.2rem;
    background-color: #fff;
    bottom: -7px;
    right: 3px;
    border-radius: 0.5rem;
    font-size: 0.65rem;
  }

  .pagethumb {
    height: fit-content;
  }

  .pagethumb .pageView {
    height: 6rem;
  }

  .pageAdder {
    height: 5rem;
  }
}

@media screen and (max-width: 950px) {
  .viewToolbarButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer {
    flex-direction: row;
    row-gap: 1rem;
  }
}

@media screen and (min-width: 950px) {
  .footer {
    flex-direction: row;
    row-gap: 1rem;
  }
}

/* fullscreen */

.fullscreenCloseIcon {
  position: fixed;
  right: 2rem;
  top: 2rem;
}

.pageChangeButtons {
  width: 100%;
  position: fixed;
  top: 50%;
  left: 0;
  display: flex;
  justify-content: space-between;
}

.fullscreenLeftButton,
.fullscreenRightButton {
  cursor: pointer;
}

.fullscreenLeftButton {
  margin-left: 1rem;
}

.fullscreenRightButton {
  margin-right: 1rem;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.proofreadingContainer {
  margin-top: 1.5rem;
}

.proofreadingContainer>p {
  margin-bottom: 0.5rem;
}

.proofreadingGrid {
  display: grid;
  gap: 1rem;
}

.subGrid {
  display: grid;
  grid-template-columns: subgrid;
  gap: 1rem;
  height: -moz-fit-content;
  height: fit-content;
  grid-column: span 3;
}

.subGrid>div {
  display: flex;
  align-items: center;
  justify-content: center;
}